<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Body -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Laporan Informasi Dokumen PKS</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <!-- BEGIN:Petunjuk -->
              <iq-card
                class="
                  iq-card-block iq-bg-info
                  text-dark
                  iq-card-stretch iq-card-height
                  p-3
                "
              >
                <div>
                  <h6 class="font-weight-bold text-center">
                    INFORMASI PKS YANG SUDAH/BELUM UPLOAD DOKUMEN
                  </h6>
                </div>
              </iq-card>
              <!-- ./END:Petunjuk -->
              <!-- BEGIN:Table -->
              <div class="card mt-4">
                <b-tabs
                  active-nav-item-class="iq-bg-primary"
                  active-tab-class="bg-light"
                  no-fade
                  pills
                  card
                  justified
                >
                  <!-- BEGIN:Left -->
                  <b-tab
                    title="Sudah Upload Dokumen"
                    title-link-class="text-light font-weight-bold bg-success mx-2"
                    active
                  >
                    <div class="card-body">
                      <div class="align-items-center">
                        <b-row>
                          <b-col>
                            <b-form-input
                              class="w-25"
                              id="filter-input"
                              v-model="filterSudahUpload"
                              type="search"
                              placeholder="Cari Data"
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-table
                          responsive
                          bordered
                          :fields="theadSudahUpload"
                          :items="listDataSudahUpload"
                          class="mt-2"
                          :busy="isLoadingSudahUpload"
                          :filter="filterSudahUpload"
                          :per-page="perPageSudahUpload"
                          :current-page="currentPageSudahUpload"
                          @filtered="onFilteredSudahUpload"
                          show-empty
                        >
                          <template
                            v-slot:cell(status_upload)="listDataSudahUpload"
                          >
                            <div class="text-success">
                              {{ listDataSudahUpload.item.status_upload }}
                            </div>
                          </template>
                          <template #table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>
                          <template #empty>
                            <div
                              class="
                                bg-light
                                text-danger
                                font-weight-bold
                                mx-auto
                                text-center
                              "
                            >
                              Data tidak ditemukan
                            </div>
                          </template>
                        </b-table>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Per page:"
                              label-for="per-page-select"
                              label-size="sm"
                              class="d-flex"
                            >
                              <b-form-select
                                class="ml-2"
                                id="per-page-select"
                                v-model="perPageSudahUpload"
                                :options="pageOptionsSudahUpload"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-pagination
                              class="float-right"
                              v-model="currentPageSudahUpload"
                              :total-rows="totalRowsSudahUpload"
                              :per-page="perPageSudahUpload"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-tab>
                  <!-- ./END:Left -->
                  <!-- BEGIN:Right -->
                  <b-tab
                    title="Belum Upload Dokumen"
                    title-link-class="text-light font-weight-bold bg-danger mx-2"
                  >
                    <div class="card-body">
                      <div class="align-items-center">
                        <b-row>
                          <b-col>
                            <b-form-input
                              class="w-25"
                              id="filter-input"
                              v-model="filterBelumUpload"
                              type="search"
                              placeholder="Cari Data"
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-table
                          responsive
                          bordered
                          :fields="theadBelumUpload"
                          :items="listDataBelumUpload"
                          class="mt-2"
                          :busy="isLoadingBelumUpload"
                          :filter="filterBelumUpload"
                          :per-page="perPageBelumUpload"
                          :current-page="currentPageBelumUpload"
                          @filtered="onFilteredBelumUpload"
                          show-empty
                        >
                          <template
                            v-slot:cell(status_upload)="listDataBelumUpload"
                          >
                            <div class="text-danger">
                              {{ listDataBelumUpload.item.status_upload }}
                            </div>
                          </template>
                          <template #table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>
                          <template #empty>
                            <div
                              class="
                                bg-light
                                text-danger
                                font-weight-bold
                                mx-auto
                                text-center
                              "
                            >
                              Data tidak ditemukan
                            </div>
                          </template>
                        </b-table>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Per page:"
                              label-for="per-page-select"
                              label-size="sm"
                              class="d-flex"
                            >
                              <b-form-select
                                class="ml-2"
                                id="per-page-select"
                                v-model="perPageBelumUpload"
                                :options="pageOptionsBelumUpload"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-pagination
                              class="float-right"
                              v-model="currentPageBelumUpload"
                              :total-rows="totalRowsBelumUpload"
                              :per-page="perPageBelumUpload"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-tab>
                  <!-- ./END:Right -->
                </b-tabs>
              </div>
              <!-- ./END:Table -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Body -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
// import { Vue } from 'vue-property-decorator'
// import store from '@/store/index'
// import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'laporan.informasiDokumenPks',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        (this.form.semuaTahun || this.form.tahun) && this.form.upbjj !== null
      return status
    }
  },
  async mounted() {
    this.innitData()
    /**
     * Set the initial number of items
     */
    this.totalRowsSudahUpload = this.listDataSudahUpload.length
    this.totalRowsBelumUpload = this.listDataBelumUpload.length
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFilteredSudahUpload(filteredItems) {
      this.totalRowsSudahUpload = filteredItems.length
      this.currentPageSudahUpload = 1
    },
    onFilteredBelumUpload(filteredItems) {
      this.totalRowsBelumUpload = filteredItems.length
      this.currentPageBelumUpload = 1
    },
    /**
     * Set checkbox semua tahun
     */
    setSemuaTahun(event) {
      event.target.checked === true
        ? (this.form.semuaTahun = 1)
        : (this.form.semuaTahun = 0)
    },
    /**
     * Get data UPBJJ
     */
    async getDataKategoriPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=jenisPks`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataUpbjj = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Search Data
     */
    searchData() {
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Init Data
     */
    async innitData() {
      /**
       * Get List Data
       */
      await Promise.all([this.getDataKategoriPks_()])
    }
  },
  data() {
    return {
      // Tabel Options Sudah Upload
      isLoadingSudahUpload: false,
      filterSudahUpload: null,
      perPageSudahUpload: 5,
      pageOptionsSudahUpload: [5, 10, 15, { value: 100, text: '100' }],
      currentPageSudahUpload: 1,
      totalRowsSudahUpload: 1,
      // Tabel Options Belum Upload
      isLoadingBelumUpload: false,
      filterBelumUpload: null,
      perPageBelumUpload: 5,
      pageOptionsBelumUpload: [5, 10, 15, { value: 100, text: '100' }],
      currentPageBelumUpload: 1,
      totalRowsBelumUpload: 1,
      // Form
      form: {
        semuaTahun: null,
        tahun: null,
        upbjj: null
      },
      // Data UPBJJ
      listDataUpbjj: [],
      // Table Sudah Upload
      theadSudahUpload: [
        { label: 'No', key: 'id', class: 'text-center' },
        { label: 'Kode PKS', key: 'kode_pks', class: 'text-center' },
        { label: 'Nama PKS', key: 'nama_pks', class: 'text-justify w-50' },
        { label: 'Status', key: 'status_upload', class: 'text-center' }
      ],
      listDataSudahUpload: [
        {
          id: 1,
          kode_pks: '000001',
          nama_pks: 'Program Beasiswa Di Universitas Terbuka',
          status_upload: 'Sudah Upload Dokumen'
        }
      ],
      // Table Belum Upload
      theadBelumUpload: [
        { label: 'No', key: 'id', class: 'text-center' },
        { label: 'Kode PKS', key: 'kode_pks', class: 'text-center' },
        { label: 'Nama PKS', key: 'nama_pks', class: 'text-justify w-50' },
        { label: 'Status', key: 'status_upload', class: 'text-center' }
      ],
      listDataBelumUpload: [
        {
          id: 1,
          kode_pks: '000003',
          nama_pks:
            'Peningkatan Akses Sistem Pendidikan Tinggi Terbuja dan jarak Jauh UT',
          status_upload: 'Belum Upload Dokumen'
        }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    return next()
    // const idUnitKs = store.state.authService.idUnitKs
    // if (MENU_ROLE.DAAK_DAN_KEUANGAN.includes(String(idUnitKs))) {
    //   return next()
    // } else {
    //   Vue.$toast.error('Anda tidak memiliki hak akses')
    //   return next({ name: 'sidebar.dashboard' })
    // }
  }
}
</script>
